@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BarlowCondensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  font-family: 'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bangers-font {
  font-family: 'Bangers', cursive !important  ;
}

.barlow-condensed-font {
  font-family: 'BarlowCondensed', sans-serif !important;
}

.montserrat-font {
  font-family: 'Montserrat', sans-serif !important;
}

/* Reducir el margen entre el título y el texto */
.swal2-title-custom {
  margin-bottom: 500px !important; /* Ajusta este valor según sea necesario */
}

.swal2-content-custom {
  margin-top: 5px !important; /* Ajusta este valor según sea necesario */
}
